import Swal from "sweetalert2";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import IconButton from "components/common/IconButton";
import FalconCloseButton from "components/common/FalconCloseButton";
import { useForm } from "react-hook-form";
import { isError } from "components/helpers/response";
import { toast } from "react-toastify";
import EmployeeService from "services/employee/employee";
import manager from "../../services/manager/manager";
import EatwellService from "../../services/eatwell/eatwell";

const Employee = () => {
  const dispatch = useDispatch();
  const [dataTableData, setDataTableData] = useState([]);
  const [view, setView] = useState(false);
  const [getGender, setGender] = useState("");
  const [image, imageStore] = useState("");
  const [fileDocList, setFileDocList] = useState("");
  const [mainCategory, mainCategorys] = useState("");
  const [valueCategory, setCategory] = useState([]);

  const [showSubscription, setShowSubscription] = useState(false);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPlanName, setSelectedPlanName] = useState("63e8d49d148d1035e10bf64e");
  const [selectedPlanSeats, setSelectedPlanSeats] = useState(0);
  const [usedSeats, setUsedSeats] = useState(0);
  const [selectedPlanPricePerUser, setSelectedPlanPricePerUser] = useState(0);
  const [selectedPlanDate, setSelectedPlanDate] = useState(new Date());
  const [selectedPlanFrequency, setSelectedPlanFrequency] = useState("monthly");

  const [newMobile, setNewMobile] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const handleClose = () => {
    reset({ keepDirtyValues: true }, { keepIsValid: true });
    setView(false);
  };
  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getData();
    getType();
    isError(errors);
  }, []);

  useEffect(() => {
    isError(errors);
  });

  const getType = () => {
    dispatch(EmployeeService.getManager())
      .then((res) => {
        setCategory(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const handleView = (id) => {
    var data = {
      id: id,
    };
    dispatch(EmployeeService.edit_manager(data))
      .then((res) => {
        setView(true);
        const img = `${process.env.REACT_APP_FILE_URL}${res.data.profile_image}`;
        setFileDocList(img);
        setGender(res.data.gender);
        setValue("email", res.data.email);
        setValue("mobile", res.data.mobile);
        setValue("firstname", res.data.firstname);
        setValue("lastname", res.data.lastname);
        setValue("id", res.data._id);
        setValue("manager_id", res.data.manager_id);
        setNewMobile(res.data.mobile);
        setNewEmail(res.data.email);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const showSubscriptionPanel = (data) => {
    console.log(data);
    setShowSubscription(!showSubscription);
  }

  // datatable data

  const getData = () => {
    dispatch(EmployeeService.getEmployee())
      .then((res) => {
        setDataTableData(res.data);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const handleSubscriptionClose = () => {
    setShowSubscription(false);
  }

  const subscribe_to_plan = () => {

    const data = {
      "plan_user": selectedUser,
      "plan_name": selectedPlanName,
      "plan_seats": selectedPlanSeats,
      "used_seats": usedSeats,
      "plan_price": selectedPlanPricePerUser,
      "plan_date": selectedPlanDate,
      "frequency": selectedPlanFrequency,
    }
    console.log("PLAN DATA: ", data);

    dispatch(manager.setupPlanPermissions(data))
      .then((res) => {
        toast.success("Plan Updated Successfully!");
        getData();
        handleSubscriptionClose();
      })
      .catch((errors) => {
        console.log(errors.data.message);
      });
  }

  const update_employee = (data) => {

    var dataInfo = {
      id: data.id,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: data.mobile,
    };
    console.log(dataInfo);

    dispatch(EmployeeService.update_employee(dataInfo))
      .then((res) => {
        getData();
        handleClose();
      })
      .catch((errors) => {
        console.log(errors.data.message);
      });
  };
  //data table
  const handleChangeImageUpload = (event) => {
    var image_pre = event.target.files[0];

    dispatch(EatwellService.upload_file(image_pre))
      .then((res) => {
        if (res.status == "200") {
          imageStore(res.data.file_url);
        }
      })
      .catch((errors, statusCode) => {
        console.log(errors.data.message, "danger");
      });
  };
  const columns = [
    {
      accessor: "_id",
      Header: "No",
      Cell: (rowData) => {
        return parseInt(rowData.row.id) + 1;
      },
    },
    {
      accessor: "fullname",
      Header: "Name",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      accessor: "mobile",
      Header: "Mobile",
    },
    {
      accessor: "company",
      Header: "Organisation",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <p>{row.company.company_name ? row.company.company_name : "No Organisation Name"}</p>
          </>
        )
      }
    },
    {
      accessor: "createdAt",
      Header: "Action",
      Cell: (rowData) => {
        const row = rowData.row.original;
        return (
          <>
            <div className="t-action-btn">
              <button
                className="btn btn-info"
                onClick={() => {
                  console.log(row);
                  handleView(row._id);
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>

              <button
                className="btn btn-success"
                onClick={() => {
                  showSubscriptionPanel(row);
                  setSelectedUser(row._id);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={dataTableData}
        sortable
        pagination
        perPage={10}
      >
        <div
          style={{ borderRadius: "0.375rem" }}
          className="py-4 bg-white mb-3 d-flex align-items-center px-3"
        >
          <h5 className="hover-actions-trigger mb-0">People</h5>
        </div>
        <Card className="mb-3">
          <Card.Header className="border-bottom border-200">
            <Row className="flex-between-center align-items-end g-2">
              <Col xs={8} sm="auto" className="ms-3 mt-2 text-end ps-0"></Col>
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
          </Card.Header>
          <Row className="flex-end-center mb-3">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs--1 mb-0 overflow-hidden",
              }}
            />
          </Row>
        </Card>

        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={dataTableData.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>

      {/* update cms model  */}

      <Modal
        show={view}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update User</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Form noValidate onSubmit={handleSubmit(update_employee)}>
          <Modal.Body>
            <Form.Control type="hidden" id="id" name="id" {...register("id")} />

            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                id="firstname"
                name="firstname"
                placeholder="Enter Users First Name"
                isInvalid={!!errors.firstname}
                {...register("firstname", {
                  required: "A First Name is Required",
                  pattern: {
                    value: /.*\S.*/,
                    message: "White space is not allowed",
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Enter Users Surname"
                isInvalid={!!errors.lastname}
                {...register("lastname", {
                  required: "A Surname is Required",
                  pattern: {
                    value: /.*\S.*/,
                    message: "White space is not allowed",
                  },
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email Address (will be used as Login Username)</Form.Label>
              <Form.Control
                type="text"
                id="email"
                name="email"
                placeholder="Enter an Email Address"
                onChange={(value) => setNewEmail(value.target.value)}
                isInvalid={!!errors.email}
                {...register("email", {
                  required: "A valid Email Address is Required",
                })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Mobile Number (may trigger User Verification if changed)</Form.Label>
              <Form.Control
                type="text"
                id="mobile"
                name="mobile"
                placeholder="Enter Users Mobile Number"
                onChange={(value) => setNewMobile(value.target.value)}
                isInvalid={!!errors.mobile}
                {...register("mobile", {
                  required: "A valid Mobile Number is Required",
                  minLength: {
                    value: 10,
                    message: "Minimum length is 10 digits (Mobile Numbers only)",
                  },
                })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update User
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSubscription}
        onHide={handleSubscriptionClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add/Update a Subscription</Modal.Title>
          <FalconCloseButton onClick={handleSubscriptionClose} />
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Control type="hidden" id="id" name="id" {...register("id")} />

            <Form.Group className="mb-3">

              <Form.Label>Plan</Form.Label>
              <Form.Select onChange={(value) => setSelectedPlanName(value.target.value)} value={selectedPlanName} name="package_name" aria-label="Default select example">
                <option disabled>Select Plan</option>
                <option value="63e8d49d148d1035e10bf64e">Heart</option>
                <option value="63e8d4b8148d1035e10bf64f">Core</option>
                <option value="63e8d4df148d1035e10bf650">Expand</option>
                <option value="63e8d555148d1035e10bf651">Optimum</option>
              </Form.Select>

              <Form.Label className="mt-2">Active Seats</Form.Label>
              <Form.Control onChange={(value) => setSelectedPlanSeats(value.target.value)} value={selectedPlanSeats} name="seats">
              </Form.Control>

              <Form.Label className="mt-2">Used Seats</Form.Label>
              <Form.Control onChange={(value) => setUsedSeats(value.target.value)} value={usedSeats} name="seats">
              </Form.Control>

              <Form.Label className="mt-2">Price Per User</Form.Label>
              <Form.Control onChange={(value) => setSelectedPlanPricePerUser(value.target.value)} value={selectedPlanPricePerUser} name="price_per_user" type={"number"}>
              </Form.Control>

              <Form.Label className="mt-2">Subscription Start Date</Form.Label>
              <Form.Control onChange={(value) => setSelectedPlanDate(value.target.value)} value={selectedPlanDate} name="renewal_date" type={"date"}>
              </Form.Control>

              <Form.Label className="mt-2">Renewal Frequency</Form.Label>
              <Form.Select onChange={(value) => setSelectedPlanFrequency(value.target.value)} value={selectedPlanFrequency} name="plan_frequency" aria-label="Default select example">
                <option disabled>Renewal Frequency</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </Form.Select>

            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSubscriptionClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={subscribe_to_plan}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

// export default Interest;
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    accessToken: state.Auth.accessToken,
  };
};
export default connect(mapStateToProps)(Employee);
