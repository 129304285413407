import url from "./../../Development.json";
import Http from "../../security/Http";
import ToastMe from "../../components/common/ToastMe";
import { successResponse } from "components/helpers/response";

const EmployeeService = {
  // getData
  getEmployee: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.get_employee)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  }, // update exersise
  update_employee: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.update_employee, data)
          .then(function (res) {
            successResponse(res);
            return resolve(res);
          })
          .catch(function (err) {
            ToastMe(err.response.data.message, "danger");

            const statusCode = err.response.status;
            const data = {
              errors: err.response.data.errors,
              statusCode,
              data: err.response.data,
            };
            return reject({ data });
          });
      });
  },
  edit_manager: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.edit_manager, data)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            // handle error
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  },
  getManager: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.get_manager_detail)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  },
};

export default EmployeeService;
