import url from "./../../Development.json";
import Http from "../../security/Http";
import * as action from "../../store/actions";
import ToastMe from "../../components/common/ToastMe";
import { successResponse } from "components/helpers/response";

const EapService = {

  //get data
  getData: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.get_eap)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            // handle error
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  },


  featureSupportConnectProvider: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.feature_eap, data)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            // handle error
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  },


  //get data
  deleteSupportConnectProvider: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.callApi(url.delete_eap, data)
          .then(function (res) {
            return resolve(res);
          })
          .catch(function (err) {
            // handle error
            let errorData, statusCode;
            if (err.response != undefined) {
              errorData = err.response.data.errors;
              statusCode = err.response.status;
            }
            return reject({
              errorData,
              statusCode,
            });
          });
      });
  },

  // add/update EAP
  addEap: (data) => {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        const descData = Object.values(data.options);
        //update eap
        if (data.id) {
          var dataInfo = {
            id: data.id,
            image: data.image,
            mobile: data.mobile,
            featured: data.featured,
            options: descData ?? null,
          };
          Http.callApi(url.update_eap, dataInfo)
            .then(function (res) {
              successResponse(res);
              return resolve(res);
            })
            .catch(function (err) {
              ToastMe(err.response.data.message, "danger");

              const statusCode = err.response.status;
              const data = {
                errors: err.response.data.errors,
                statusCode,
                data: err.response.data,
              };
              return reject({ data });
            });
        } else {
          //add eap
          var dataInfo = {
            image: data.image,
            mobile: data.mobile,
            featured: data.featured,
            options: descData ?? null,
          };
          Http.callApi(url.add_eap, dataInfo)
            .then(function (res) {
              successResponse(res);
              return resolve(res);
            })
            .catch(function (err) {
              ToastMe(err.response.data.message, "danger");

              const statusCode = err.response.status;
              const data = {
                errors: err.response.data.errors,
                statusCode,
                data: err.response.data,
              };
              return reject({ data });
            });
        }
      });
  },
};

export default EapService;
