export const appRoutes = {
  label: "Mind Pulse",
  children: [
    {
      name: "Dashboard",
      icon: "chart-pie",
      to: "/admin/dashboard",
      active: true,
    },
    {
      name: "In-App Legal Content",
      icon: "question-circle",
      to: "/admin/cms",
      active: true,
    },
    {
      name: "Page Inner Titles",
      icon: "question-circle",
      to: "/admin/page-titles",
      active: true,
    },
  ],
};


export const userRoutes = {
  label: "Users & Businesses",
  children: [
    {
      name: "Businesses & Organisations",
      icon: "user",
      to: "/admin/manager",
      active: true,
    },
    {
      name: "People",
      icon: "users",
      to: "/admin/employee",
      active: true,
    },
  ]
};


export const recipeRoutes = {
  label: "Recipes",
  children: [
    {
      name: "Recipe Types",
      icon: "file-alt",
      to: "/admin/eatwellType",
      active: true,
    },
    {
      name: "Recipes",
      icon: ["fab", "trello"],
      to: "/admin/eatwell",
      active: true,
    },
  ]
};

export const exerciseRoutes = {
  label: "Exercise",
  children: [
    {
      name: "Exercise Routine Types",
      icon: "file-alt",
      to: "/admin/exersiseType",
      active: true,
    },
    {
      name: "Exercise Routines",
      icon: "puzzle-piece",
      to: "/admin/exersise",
      active: true,
    },
  ]
};

export const audioRoutes = {
  label: "Audio Content",
  children: [
    {
      name: "Mindfulness",
      icon: ["fab", "trello"],
      to: "/admin/mindfulness",
      active: true,
    },
    {
      name: "Virtual Commute",
      icon: ["fab", "trello"],
      to: "/admin/virtualcommute",
      active: true,
    },
  ]
};

export const generalFunctionalityRoutes = {
  label: "General Functionality",
  children: [
    {
      name: "Support Connect",
      icon: "thumbtack",
      to: "/admin/support-connect",
      active: true,
    },
    {
      name: "App Settings",
      icon: "cog",
      to: "/admin/setting",
      active: true,
    },
  ]
};

export default [appRoutes, userRoutes, recipeRoutes, exerciseRoutes, audioRoutes, generalFunctionalityRoutes];
